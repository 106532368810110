import React, { useEffect, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CreditcardContext } from '../../../context/creditcard.context';
import { CompanyContext } from '../../../context/company.context';
import { LangContext } from '../../../context/lang.context';
import { navigateToError, redirectWhenStatusPageIsNotValid } from '../../../utils/navigate';
import { Loading } from '../../../components/287634/loading';
import pages from '../../../constants/pages.json';
import Layout from '../../../components/287634/layout/layout';
import { diffTwoDatesInSeconds as diff, isGreaterThan, isTrue } from '../../../utils/functions';
import { FormCvv } from '../../../components/287634/form/cvv-card';

const RenderLayout = ({ render, get, token, user, cardImg }: any) => {
  const { getPublicURL } = useContext(CompanyContext);
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          CVV {
            TITLE
            SUBTITLE
          }
          PAYMENT_METHODS {
            VISA
            VISA_DEBITO
          }
        }
      }
    }
  `);
  const translatePaymentMethod = (text: string) => text?.replace(/ /g, '_');

  if (!render) {
    return <Loading />;
  }

  return (
    <Layout>
      <section className="px-5">
        <h2 className="mt-[60px] mb-5 text-center text-2xl font-medium text-primary">{t(data).CVV.TITLE}</h2>
        <h3 className="mb-5 text-center text-lg font-medium text-primary">
          <span>
            {t(data).PAYMENT_METHODS[translatePaymentMethod(user?.paymentMethodName)] || user?.paymentMethodName}{' '}
          </span>
          <span>{t(data).CVV.SUBTITLE} </span>
          <span>{user.lastFourNumbers}</span>
        </h3>
        <img
          className="my-6 w-full"
          loading="eager"
          src={
            cardImg === 'cvv'
              ? getPublicURL(`/theme/assets/images/cvv/cvv-card-back.png`)
              : getPublicURL(`/theme/assets/images/cvv/cvv-card-amex.png`)
          }
        />
        <FormCvv get={get} token={token} user={user} />
      </section>
    </Layout>
  );
};

const CvvPage = ({ get, getToken }: PageProps) => {
  const { infoUser, timeStamp, getCvv } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();
  const { cvvSize } = infoUser;

  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else {
      getCvv?.(token);
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp, {
        expired: {
          state: {
            messageMiddleFrom: pages.USER.UPDATE_USER_DETAIL,
          },
        },
      });
    }
  }, [infoUser, timeStamp]);

  const cardImg = ['cvv', 'cvvAmex'][+!!(cvvSize >> 2)];
  const render = [allValid, !!diff(timeStamp ?? '', infoUser?.expirationTime ?? ''), !infoUser.status].every(isTrue);
  return <RenderLayout render={render} get={get} token={token} user={infoUser} cardImg={cardImg} />;
};

export default CvvPage;
